import React, { useState, useEffect } from "react";
import "../css/dashboard.css";
import dashimg from "./image/dashimg.png";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';
import AOS from "aos";

const Dashboard = () => {
  const navigate = useNavigate();
  
  const [doctor_mobile, doctor_mobileSet] = useState('');
  const [password, passwordSet] = useState('');
  const [newPassword, newPasswordSet] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [loginError, setLoginError] = useState('');

  const doctormobileHandler = (event) => {
    doctor_mobileSet(event.target.value);
  }
  
  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }

  const newPasswordHandler = (event) => {
    newPasswordSet(event.target.value);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }



  useEffect(() => {
        AOS.init({
          disable: "phone",
          duration: 1000,
          easing: "ease-out-cubic",
    
          
        });
      }, []);
    
       // Scroll back to top
       const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }; 

  const validateForm = () => {
    let formErrors = {};

    if (!doctor_mobile) {
      formErrors.doctor_mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(doctor_mobile)) {
      formErrors.doctor_mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!isResettingPassword && !password) {
      formErrors.password = "Password is required";
    } else if (isResettingPassword && !newPassword) {
      formErrors.newPassword = "New password is required";
    } else if ((isResettingPassword ? newPassword : password).length < 4) {
      formErrors[isResettingPassword ? 'newPassword' : 'password'] = "Password must be at least 4 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    setLoginError(''); // Clear any previous error messages

    if (validateForm()) {
      let data = { doctor_mobile: doctor_mobile };
      if (isResettingPassword) {
        data.newPassword = newPassword;
      } else {
        data.password = password;
      }

      const url = isResettingPassword ? 'http://localhost:8080/doctor/resetpassword/' : 'http://localhost:8080/doctor/doctorlogin/';

      axios.post(url, data).then((response) => {
          if (response.data.status === 200) {
            if (isResettingPassword) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Password reset successfully. Please login with your new password.",
              });
              setIsResettingPassword(false);
              newPasswordSet('');
            } else {
              localStorage.setItem('id', response.data.message.doctordata[0].id);
              localStorage.setItem('doctor_mobile', response.data.message.doctordata[0].doctor_mobile);
              localStorage.setItem('doctor_name', response.data.message.doctordata[0].doctor_name);
              localStorage.setItem('doctor_email', response.data.message.doctordata[0].doctor_email);
              
              navigate('/drdashboard');
            }
            doctor_mobileSet('');
            passwordSet('');
          } else {
            setLoginError(response.data.message || 'Login failed. Please check your credentials and try again.');
            // Swal.fire({
            //   icon: "error",
            //   title: "Oops...",
            //   text: response.data.message,
            // });
          }
        })
      .catch((error) => {
      console.error("Login error:", error);
      setLoginError('Login failed. Please check your credentials and try again.');

      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Login failed. Please check your credentials and try again.",
        
      // });
    });
    }
  }
  return (
    <div className="dashboard-container" data-aos="fade-right">
      <form name="loginform" onSubmit={submitHandler}>
        <div className="loginbox">
          <div className="formbox">
            <h3>{isResettingPassword ? "Reset Password" : "Login to Doctor"}</h3>
            {loginError && (
              <div className="error-message">
              <i class="ri-error-warning-line"  aria-hidden="true"></i>
                <span>{loginError}</span>
              </div>
            )}
            <div className="input-field">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <input 
                type="text" 
                placeholder="Enter your mobile" 
                name="doctor_mobile" 
                onChange={doctormobileHandler}
                id="doctor_mobile" 
                value={doctor_mobile}
              />
              {errors.doctor_mobile && <div className="error">{errors.doctor_mobile}</div>}
            </div>
            {!isResettingPassword ? (
              <div className="input-field">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password" 
                  name="password" 
                  onChange={passwordHandler}
                  id="password" 
                  value={password}
                />
                <i 
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                  onClick={togglePasswordVisibility}
                ></i>
                {errors.password && <div className="error">{errors.password}</div>}
              </div>
            ) : (
              <div className="input-field">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password" 
                  name="newPassword" 
                  onChange={newPasswordHandler}
                  id="newPassword" 
                  value={newPassword}
                />
                <i 
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                  onClick={togglePasswordVisibility}
                ></i>
                {errors.newPassword && <div className="error">{errors.newPassword}</div>}
              </div>
            )}
            <a className="drforgot" onClick={() => setIsResettingPassword(!isResettingPassword)}>
              {isResettingPassword ? "Back to Login" : "Forgot password ?"}
            </a>
            <div className="drsignup">
              Don't have an account ? <a onClick={() => navigate('/drregistration')}>Signup</a>
            </div>
            <section>
              <button className="loginbtn" type="submit">{isResettingPassword ? "Reset Password" : "Login"}</button>
            </section>
            {errors.form && <div className="error">{errors.form}</div>}
            <div className="or">Or</div>
            <section>
              <button className="patient" type="button" onClick={() => navigate('/patient')}>As a Patient</button>
            </section>
          </div>
          <div className="loginimg">
            <img src={dashimg} alt="" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Dashboard;


// without reset password 


// import React, { useState, useEffect } from "react";
// import "../css/dashboard.css";
// import dashimg from "./image/dashimg.png";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import AOS from "aos";

// const Dashboard = () => {
//   const navigate = useNavigate();
  
//   const [doctor_mobile, doctor_mobileSet] = useState('');
//   const [password, passwordSet] = useState('');
//   const [showPassword, setShowPassword] = useState(false);

//   const doctormobileHandler = (event) => {
//     doctor_mobileSet(event.target.value);
//   }
  
//   const passwordHandler = (event) => {
//     passwordSet(event.target.value);
//   }

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   }

  
//   useEffect(() => {
//     AOS.init({
//       disable: "phone",
//       duration: 1000,
//       easing: "ease-out-cubic",

      
//     });
//   }, []);

//    // Scroll back to top
//    const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth"
//     });
//   };

//   const submitHandler = (event) => {
//     event.preventDefault();

//     let loginData = { doctor_mobile: doctor_mobile, password: password };
//     axios.post('http://localhost:8080/doctor/doctorlogin/', loginData)
//       .then((response) => {
//         console.log(response.data.message.doctordata[0]);
        
//         doctor_mobileSet('');
//         passwordSet('');

//         if (response.data) {
//           localStorage.setItem('id', response.data.message.doctordata[0].id);
//           localStorage.setItem('doctor_mobile', response.data.message.doctordata[0].doctor_mobile);
//           localStorage.setItem('doctor_name', response.data.message.doctordata[0].doctor_name);
//           localStorage.setItem('doctor_email', response.data.message.doctordata[0].doctor_email);
//           navigate('/drdashboard');
//         }
//       })
//       .catch((error) => {
//         console.error("Login error:", error);
//       });
//   }

//   return (
//     <div className="dashboard-container" data-aos="fade-right">
//       <form name="loginform" onSubmit={submitHandler}>
//         <div className="loginbox">
//           <div className="formbox">
//             <h3>Login to Doctor</h3>
//             <div className="input-field">
//               <i className="fa fa-mobile" aria-hidden="true"></i>
//               <input 
//                 type="text" 
//                 placeholder="Enter your mobile" 
//                 name="doctor_mobile" 
//                 onChange={doctormobileHandler}
//                 id="doctor_mobile" 
//                 value={doctor_mobile}
//               />
//             </div>
//             <div className="input-field">
//               <i className="fa fa-lock" aria-hidden="true"></i>
//               <input 
//                 type={showPassword ? "text" : "password"}
//                 placeholder="Enter your password" 
//                 name="password" 
//                 onChange={passwordHandler}
//                 id="password" 
//                 value={password}
//               />
//               <i 
//                 className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
//                 aria-hidden="true"
//                 onClick={togglePasswordVisibility}
//               ></i>
//             </div>
//             <a className="drforgot">Forgot password ?</a>
//             <div className="drsignup">
//               Don't have an account ? <a onClick={() => navigate('/drregistration')}>Signup</a>
//             </div>
//             <section>
//               <button className="loginbtn" type="submit">Login</button>
//             </section>
//             <div className="or">Or</div>
//             <section>
//               <button className="patient" type="button" onClick={() => navigate('/patient')}>As a Patient</button>
//             </section>
//           </div>
//           <div className="loginimg">
//             <img src={dashimg} alt="" />
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Dashboard;





// import React, { useState } from "react";
// import "../css/dashboard.css";
// import dashimg from "./image/dashimg.png";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import Swal from 'sweetalert2';

// const Dashboard = () => {
//   const navigate = useNavigate();
  
//   const [doctor_mobile, doctor_mobileSet] = useState('');
//   const [password, passwordSet] = useState('');
//   const [newPassword, newPasswordSet] = useState('');
//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const [isResettingPassword, setIsResettingPassword] = useState(false);
//   const [loginError, setLoginError] = useState('');

//   const doctormobileHandler = (event) => {
//     doctor_mobileSet(event.target.value);
//   }
  
//   const passwordHandler = (event) => {
//     passwordSet(event.target.value);
//   }

//   const newPasswordHandler = (event) => {
//     newPasswordSet(event.target.value);
//   }

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   }

//   const validateForm = () => {
//     let formErrors = {};

//     if (!doctor_mobile) {
//       formErrors.doctor_mobile = "Mobile number is required";
//     } else if (!/^\d{10}$/.test(doctor_mobile)) {
//       formErrors.doctor_mobile = "Please enter a valid 10-digit mobile number";
//     }

//     if (!isResettingPassword && !password) {
//       formErrors.password = "Password is required";
//     } else if (isResettingPassword && !newPassword) {
//       formErrors.newPassword = "New password is required";
//     } else if ((isResettingPassword ? newPassword : password).length < 4) {
//       formErrors[isResettingPassword ? 'newPassword' : 'password'] = "Password must be at least 4 characters long";
//     }

//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0;
//   }

//   const submitHandler = (event) => {
//     event.preventDefault();
//     setLoginError(''); // Clear any previous error messages

//     if (validateForm()) {
//       let data = { doctor_mobile: doctor_mobile };
//       if (isResettingPassword) {
//         data.newPassword = newPassword;
//       } else {
//         data.password = password;
//       }

//       const url = isResettingPassword ? 'http://localhost:8080/doctor/resetpassword/' : 'http://localhost:8080/doctor/doctorlogin/';

//       axios.post(url, data).then((response) => {
//           if (response.data.status === 200) {
//             if (isResettingPassword) {
//               Swal.fire({
//                 icon: "success",
//                 title: "Success",
//                 text: "Password reset successfully. Please login with your new password.",
//               });
//               setIsResettingPassword(false);
//               newPasswordSet('');
//             } else {
//               localStorage.setItem('id', response.data.message.doctordata[0].id);
//               localStorage.setItem('doctor_mobile', response.data.message.doctordata[0].doctor_mobile);
//               localStorage.setItem('doctor_name', response.data.message.doctordata[0].doctor_name);
//               localStorage.setItem('doctor_email', response.data.message.doctordata[0].doctor_email);
              
//               navigate('/drdashboard');
//             }
//             doctor_mobileSet('');
//             passwordSet('');
//           } else {
//             setLoginError(response.data.message || 'Login failed. Please check your credentials and try again.');
//             // Swal.fire({
//             //   icon: "error",
//             //   title: "Oops...",
//             //   text: response.data.message,
//             // });
//           }
//         })
//       .catch((error) => {
//       console.error("Login error:", error);
//       setLoginError('Login failed. Please check your credentials and try again.');

//       // Swal.fire({
//       //   icon: "error",
//       //   title: "Oops...",
//       //   text: "Login failed. Please check your credentials and try again.",
        
//       // });
//     });
//     }
//   }
//   return (
//     <div className="dashboard-container">
//       <form name="loginform" onSubmit={submitHandler}>
//         <div className="loginbox">
//           <div className="formbox">
//             <h3>{isResettingPassword ? "Reset Password" : "Login to Doctor"}</h3>
//             {loginError && (
//               <div className="error-message">
//               <i class="ri-error-warning-line"  aria-hidden="true"></i>
//                 <span>{loginError}</span>
//               </div>
//             )}
//             <div className="input-field">
//               <i className="fa fa-mobile" aria-hidden="true"></i>
//               <input 
//                 type="text" 
//                 placeholder="Enter your mobile" 
//                 name="doctor_mobile" 
//                 onChange={doctormobileHandler}
//                 id="doctor_mobile" 
//                 value={doctor_mobile}
//               />
//               {errors.doctor_mobile && <div className="error">{errors.doctor_mobile}</div>}
//             </div>
//             {!isResettingPassword ? (
//               <div className="input-field">
//                 <i className="fa fa-lock" aria-hidden="true"></i>
//                 <input 
//                   type={showPassword ? "text" : "password"}
//                   placeholder="Enter your password" 
//                   name="password" 
//                   onChange={passwordHandler}
//                   id="password" 
//                   value={password}
//                 />
//                 <i 
//                   className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
//                   aria-hidden="true"
//                   onClick={togglePasswordVisibility}
//                 ></i>
//                 {errors.password && <div className="error">{errors.password}</div>}
//               </div>
//             ) : (
//               <div className="input-field">
//                 <i className="fa fa-lock" aria-hidden="true"></i>
//                 <input 
//                   type={showPassword ? "text" : "password"}
//                   placeholder="Enter new password" 
//                   name="newPassword" 
//                   onChange={newPasswordHandler}
//                   id="newPassword" 
//                   value={newPassword}
//                 />
//                 <i 
//                   className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
//                   aria-hidden="true"
//                   onClick={togglePasswordVisibility}
//                 ></i>
//                 {errors.newPassword && <div className="error">{errors.newPassword}</div>}
//               </div>
//             )}
//             <a className="drforgot" onClick={() => setIsResettingPassword(!isResettingPassword)}>
//               {isResettingPassword ? "Back to Login" : "Forgot password ?"}
//             </a>
//             <div className="drsignup">
//               Don't have an account ? <a onClick={() => navigate('/drregistration')}>Signup</a>
//             </div>
//             <section>
//               <button className="loginbtn" type="submit">{isResettingPassword ? "Reset Password" : "Login"}</button>
//             </section>
//             {errors.form && <div className="error">{errors.form}</div>}
//             <div className="or">Or</div>
//             <section>
//               <button className="patient" type="button" onClick={() => navigate('/patient')}>As a Patient</button>
//             </section>
//           </div>
//           <div className="loginimg">
//             <img src={dashimg} alt="" />
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Dashboard;












// dashboard old okay with pop up


// import React, { useState } from "react";
// import "../css/dashboard.css";
// import dashimg from "./image/dashimg.png";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import Swal from 'sweetalert2';

// const Dashboard = () => {
//   const navigate = useNavigate();
  
//   const [doctor_mobile, doctor_mobileSet] = useState('');
//   const [password, passwordSet] = useState('');
//   const [newPassword, newPasswordSet] = useState('');
//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const [isResettingPassword, setIsResettingPassword] = useState(false);

//   const doctormobileHandler = (event) => {
//     doctor_mobileSet(event.target.value);
//   }
  
//   const passwordHandler = (event) => {
//     passwordSet(event.target.value);
//   }

//   const newPasswordHandler = (event) => {
//     newPasswordSet(event.target.value);
//   }

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   }

//   const validateForm = () => {
//     let formErrors = {};

//     if (!doctor_mobile) {
//       formErrors.doctor_mobile = "Mobile number is required";
//     } else if (!/^\d{10}$/.test(doctor_mobile)) {
//       formErrors.doctor_mobile = "Please enter a valid 10-digit mobile number";
//     }

//     if (!isResettingPassword && !password) {
//       formErrors.password = "Password is required";
//     } else if (isResettingPassword && !newPassword) {
//       formErrors.newPassword = "New password is required";
//     } else if ((isResettingPassword ? newPassword : password).length < 4) {
//       formErrors[isResettingPassword ? 'newPassword' : 'password'] = "Password must be at least 4 characters long";
//     }

//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0;
//   }

//   const submitHandler = (event) => {
//     event.preventDefault();
    
//     if (validateForm()) {
//       let data = { doctor_mobile: doctor_mobile };
//       if (isResettingPassword) {
//         data.newPassword = newPassword;
//       } else {
//         data.password = password;
//       }

//       const url = isResettingPassword ? 'http://localhost:8080/doctor/resetpassword/' : 'http://localhost:8080/doctor/doctorlogin/';

//       axios.post(url, data).then((response) => {
//           if (response.data.status === 200) {
//             if (isResettingPassword) {
//               Swal.fire({
//                 icon: "success",
//                 title: "Success",
//                 text: "Password reset successfully. Please login with your new password.",
//               });
//               setIsResettingPassword(false);
//               newPasswordSet('');
//             } else {
//               localStorage.setItem('id', response.data.message.doctordata[0].id);
//               localStorage.setItem('doctor_mobile', response.data.message.doctordata[0].doctor_mobile);
//               localStorage.setItem('doctor_name', response.data.message.doctordata[0].doctor_name);
//               localStorage.setItem('doctor_email', response.data.message.doctordata[0].doctor_email);
              
//               navigate('/drdashboard');
//             }
//             doctor_mobileSet('');
//             passwordSet('');
//           } else {
//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: response.data.message,
//             });
//           }
//         })
//       .catch((error) => {
//       console.error("Login error:", error);

//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Login failed. Please check your credentials and try again.",
        
//       });
//     });
//     }
//   }
//   return (
//     <div className="dashboard-container">
//       <form name="loginform" onSubmit={submitHandler}>
//         <div className="loginbox">
//           <div className="formbox">
//             <h3>{isResettingPassword ? "Reset Password" : "Login to Doctor"}</h3>
//             <div className="input-field">
//               <i className="fa fa-mobile" aria-hidden="true"></i>
//               <input 
//                 type="text" 
//                 placeholder="Enter your mobile" 
//                 name="doctor_mobile" 
//                 onChange={doctormobileHandler}
//                 id="doctor_mobile" 
//                 value={doctor_mobile}
//               />
//               {errors.doctor_mobile && <div className="error">{errors.doctor_mobile}</div>}
//             </div>
//             {!isResettingPassword ? (
//               <div className="input-field">
//                 <i className="fa fa-lock" aria-hidden="true"></i>
//                 <input 
//                   type={showPassword ? "text" : "password"}
//                   placeholder="Enter your password" 
//                   name="password" 
//                   onChange={passwordHandler}
//                   id="password" 
//                   value={password}
//                 />
//                 <i 
//                   className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
//                   aria-hidden="true"
//                   onClick={togglePasswordVisibility}
//                 ></i>
//                 {errors.password && <div className="error">{errors.password}</div>}
//               </div>
//             ) : (
//               <div className="input-field">
//                 <i className="fa fa-lock" aria-hidden="true"></i>
//                 <input 
//                   type={showPassword ? "text" : "password"}
//                   placeholder="Enter new password" 
//                   name="newPassword" 
//                   onChange={newPasswordHandler}
//                   id="newPassword" 
//                   value={newPassword}
//                 />
//                 <i 
//                   className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
//                   aria-hidden="true"
//                   onClick={togglePasswordVisibility}
//                 ></i>
//                 {errors.newPassword && <div className="error">{errors.newPassword}</div>}
//               </div>
//             )}
//             <a className="drforgot" onClick={() => setIsResettingPassword(!isResettingPassword)}>
//               {isResettingPassword ? "Back to Login" : "Forgot password ?"}
//             </a>
//             <div className="drsignup">
//               Don't have an account ? <a onClick={() => navigate('/drregistration')}>Signup</a>
//             </div>
//             <section>
//               <button className="loginbtn" type="submit">{isResettingPassword ? "Reset Password" : "Login"}</button>
//             </section>
//             {errors.form && <div className="error">{errors.form}</div>}
//             <div className="or">Or</div>
//             <section>
//               <button className="patient" type="button" onClick={() => navigate('/patient')}>As a Patient</button>
//             </section>
//           </div>
//           <div className="loginimg">
//             <img src={dashimg} alt="" />
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Dashboard;




// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
// }
// .dashboard-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 87vh;
//   padding: 10px;
// }

// .loginbox {
//   background-color: white;
//   width: 100%;
//   max-width: 790px;
//   position: relative;
//   border-radius: 1em;
//   display: flex;
//   justify-content: space-between;
//   box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
//   overflow: hidden;
// }

// .formbox {
//   width: 50%;
//   padding: 25px;
//   display: flex;
//   flex-direction: column;
// }

// .loginimg {
//   width: 80%;
//   overflow: hidden;
// }

// .loginimg img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 10px;
// }

// .formbox h3 {
//   font-size: 27px;
//   font-weight: 500;
//   margin-bottom: 30px;
//   position: relative;
// }

// .formbox h3::after {
//   content: "";
//   width: 30px;
//   height: 3px;
//   background: rgb(10, 153, 153);
//   position: absolute;
//   left: 0;
//   bottom: 0px;
//   border-radius: 20px;
// }

// .input-field {
//   position: relative;
//   margin-bottom: 20px;
// }

// .input-field input {
//   width: 100%;
//   padding: 0px 30px 7px;
//   border: none;
//   border-bottom: 2px solid rgb(182, 180, 180);
//   font-size: 15px;
//   outline: none;
// }

// .fa {
//   color: rgb(10, 153, 153);
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: 5px;
//   font-size: 18px;
// }

// .fa-eye, .fa-eye-slash {
//   right: 5px;
//   left: auto;
//   cursor: pointer;
// }

// .drforgot, .drsignup {
//   margin-bottom: 10px;
//   cursor: pointer;

// }

// .loginbtn, .patient {

//   border-radius: 10px;
//   font-size: 18px;
//   color: #fff;
//   outline: none;
//   border: none;
//   padding: 10px;
//   width: 100%;
//   background-color: rgb(53, 188, 188);
//   background-color: #38d2d2;
//   background-image: radial-gradient(
//     93% 87% at 87% 89%,
//     rgba(0, 0, 0, 0.23) 0%,
//     transparent 86.18%
//   ),
//   radial-gradient(
//     66% 66% at 26% 20%,
//     rgba(255, 255, 255, 0.55) 0%,
//     rgba(255, 255, 255, 0) 69.79%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
//     inset 0px 3px 9px rgba(255, 255, 255, 0.3),
//     inset 0px 1px 1px rgba(255, 255, 255, 0.6),
//     inset 0px -8px 36px rgba(0, 0, 0, 0.3),
//     inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
//   user-select: none;
//   -webkit-user-select: none;
//   touch-action: manipulation;
//   cursor: pointer;
//   height: 45px;
//   transition: background-color 0.3s;


// }

// .loginbtn:hover, .patient:hover {
//   background-color: rgb(26, 176, 176);
// }

// .or {
//   text-align: center;
//   font-weight: bold;
//    margin-left: -1%;
//    bottom: 10%;
// }

// .error {
//   color: rgb(202, 47, 47);
//   font-size: 14px;
//   margin-top: 5px;
// }

// @media (max-width: 1024px) {
//   .loginimg {
//     display: none;
//   }
  
//   .formbox {
//     width: 100%;
//   }
// }

// @media (max-width: 768px) {
//   .loginbox {
//     width: 90%;
//   }
  
//   .formbox {
//     padding: 30px;
//   }
  
//   .formbox h3 {
//     font-size: 24px;
//   }
// }

// @media (max-width: 480px) {
//   .loginbox {
//     width: 100%;
//   }
  
//   .formbox {
//     padding: 20px;
//   }
  
//   .formbox h3 {
//     font-size: 20px;
//   }
  
//   .input-field input {
//     font-size: 14px;
//   }
// }

