import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from 'axios';
import "../css/drregistration.css"

const Drregistration = () => {
  const navigate = useNavigate();

  const [doctor_name, doctornameSet] = useState('');
  const [doctor_email, doctoremailSet] = useState('');
  const [doctor_mobile, doctormobileSet] = useState('');
  const [position, positionSet] = useState('');
  const [speciality, specialitySet] = useState('');
  const [experience, experienceSet] = useState('');
  const [gender, genderSet] = useState('');
  const [password, passwordSet] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const doctornameHandler = (event) =>{
    doctornameSet(event.target.value);
  } 
  const doctoremailHandler = (event) =>{
    doctoremailSet(event.target.value);
  } 
  const doctormobileHandler = (event) => {
    doctormobileSet(event.target.value);
  }
  const positionHandler = (event) => {
    positionSet(event.target.value);
  }
  const specialityHandler = (event) => {
    specialitySet(event.target.value);
  }
  const experienceHandler = (event) =>{
    experienceSet(event.target.value);
  } 
  const genderHandler = (event) => {
    genderSet(event.target.value);
  }
  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }

  const submitHandler = (event) => {
    // event.preventDefault();
    const drregistrationData = { doctor_name,doctor_email,doctor_mobile,position,speciality,experience,gender,password
    };
    
    return axios.post('http://localhost:8080/doctor/doctorregistration/',drregistrationData)
      .then((response) => {
        console.log(response);

        doctornameSet('')
        doctoremailSet('')
        doctormobileSet('')
        positionSet('')
        specialitySet('')
        experienceSet('')
        genderSet('')
        passwordSet('')

        setShowSuccessMessage(true);
        
        // Hide success message after 4 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 4000);

      });
      
  }

  return (
<>
    {showSuccessMessage && (
      <div className="success-message">
        Registration successfully !
   
      </div>
    )}
    <div className="registration-container">
      <div className="registration-card">
        <h2 className="registration-title">Doctor Registration</h2>
        
        <form onSubmit={submitHandler} className="registration-form">
          <div className="form-grid">
            <div className="input-group">
              <input 
                type="text"
                placeholder="Doctor name"
                onChange={doctornameHandler}
                required
              />
            </div>

            <div className="input-group">
              <input 
                type="email"
                placeholder="Doctor email"
                onChange={doctoremailHandler}
                required
              />
            </div>

            <div className="input-group">
              <input 
                type="tel"
                placeholder="Doctor mobile"
                onChange={doctormobileHandler}
                required
              />
            </div>

            <div className="input-group">
              <input 
                type="text"
                placeholder="Position"
                onChange={positionHandler}
                required
              />
            </div>

            <div className="input-group">
              <input 
                type="text"
                placeholder="Speciality"
                onChange={specialityHandler}
                required
              />
            </div>

            <div className="input-group">
              <input 
                type="text"
                placeholder="Experience"
                onChange={experienceHandler}
                required
              />
            </div>

            <div className="input-group">
              <select 
                onChange={genderHandler}
                required
                defaultValue=""
              >
                <option value="" disabled>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
               
              </select>
            </div>

            <div className="input-group">
              <input 
                type="password"
                placeholder="Password"
                onChange={passwordHandler}
                required
              />
            </div>
          </div>

          <div className="policy-group">
            <label className="checkbox-label">
              <input type="checkbox" required />
              <span>I accept all terms & conditions</span>
            </label>
          </div>

          <button type="submit" className="register-button">
            Register Now
          </button>

          <p className="login-link">
            Already have an account? 
            <span onClick={() => navigate('/dashboard')}> Login</span>
          </p>
        </form>
      </div>
    </div>
    </>
  );
};

export default Drregistration;