import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../css/patientdashboardheader.css'
import { FaTimes} from "react-icons/fa";

const Patientdashboardheader = () => {
  const navigate = useNavigate();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/patient");
  };

  return (
    <>
      <div className={`patient-sidebar  ${isSidebarOpen ? "open" : ""}`}>
      <button 
          className="close-sidebar" 
          onClick={toggleSidebar}
          aria-label="Close sidebar"
        >
          <FaTimes />
        </button>
           {/* Close button for sidebar */}
           {/* <button 
          className="close-sidebar" 
          onClick={toggleSidebar}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            color: '#59c7c7',
            cursor: 'pointer',
            display: isSidebarOpen ? 'block' : 'none'
          }}
        >
          <FaTimes />
        </button> */}
        <div className="patient-profile-picture-container profile-picture-container">
          <div className="patient-text-logo text logo-text">
            <span className="patient-name name">Medical</span>
            <span className="patient-profession profession">Health Care</span>
          </div>
        </div>

        <nav className="patient-nav">
          <a className="patient-nav-item active" >
            <span className="patient-icon-0 icon-0">
              <i className="ri-layout-grid-line"></i>
            </span>
            <span className="patient-nav-text">Dashboard</span>
          </a>
          <a className="patient-nav-item" >
            <span className="patient-icon-1 icon-1">
            <i class="ri-file-line"></i>
            </span>
            <span className="patient-nav-text">Report</span>
          </a>
          <a className="patient-nav-item" >
            <span className="patient-icon-2 icon-2">
              <i className="ri-user-2-line"></i>
            </span>
            <span className="patient-nav-text">Doctor Visit</span>
          </a>
          <a className="patient-nav-item" href="#">
            <span className="patient-icon-5 icon-5">
              <i className="ri-settings-3-line"></i>
            </span>
            <span className="patient-nav-text">Setting</span>
          </a>
          <a className="patient-nav-item" onClick={handleLogout}>
            <span className="patient-icon-6 icon-6">
              <i className="ri-logout-box-r-line"></i>
            </span>
            <span className="patient-nav-text">Logout</span>
          </a>
        </nav>

      
      </div>

      <div className="patient-main-content main-content">
        <header className="patient-header">
          <button className="patient-toggle-sidebar toggle-sidebar" onClick={toggleSidebar}>
            <i className={`fas ${isSidebarOpen ? "fa-times" : "fa-bars"}`}></i>
          </button>

          <section className="patient-doctorsearchbox doctorsearchbox">
            <div className="patient-search--notification--profile search--notification--profile">
              <div className="patient-search search">
                <input type="text" placeholder="Search" className="patient-search-input" />
                <button className="patient-search-button">
                  <i className="ri-search-2-line"></i>
                </button>
              </div>
          
              <div className="patient-notification--profile notification--profile">
                <div className="patient-picon picon patient-bell bell">
                  <i className="ri-notification-2-line"></i>
                </div>
                <div className="patient-picon picon patient-chat chat">
                  <i className="ri-wechat-2-line"></i>
                </div>
              </div>
            </div>
          </section>
        </header>
      </div>
    </>
  );
};

export default Patientdashboardheader;



// import '../css/patientdashboardheader.css'
// import React, { useState } from 'react';


// const Patientdashboardheader = () => {

//   const [ispatientslidebarClosed, setIspatientslidebarClosed] = useState(false);
  

 

//   const togglepatientslidebar = () => {
//     setIspatientslidebarClosed(!ispatientslidebarClosed);
//   };




//   return (
//     <>
    
  
//     <div className="App">
//       <nav className={`patientslidebar ${ispatientslidebarClosed ? 'close' : ''}`}>
//         <header>
//           <div className="image-text">
//             <span className="image">
//               <img src="logo.png" alt="" />
//             </span>

//             <div className="text logo-text">
//               <span className="name">Medical</span>
//               <span className="profession">Health Care</span>
//             </div>
//           </div>

//           <i className='bx bx-chevron-right toggle' onClick={togglepatientslidebar}></i>
//         </header>

//         <div className="menu-bar">
//           <div className="menu">
           

//             <ul className="menu-links">
//               <li className="nav-link">
//                 <a href="#">
//                   <i className='bx bx-home-alt icon'></i>
//                   <span className="text nav-text">Dashboard</span>
//                 </a>
//               </li>

//               <li className="nav-link">
//                 <a href="#">
//                   <i className='bx bx-bar-chart-alt-2 icon'></i>
//                   <span className="text nav-text">Report</span>
//                 </a>
//               </li>

//               {/* <li className="nav-link">
//                 <a href="#">
//                   <i className='bx bx-bell icon'></i>
//                   <span className="text nav-text">Notifications</span>
//                 </a>
//               </li> */}

//               <li className="nav-link">
//                 <a href="#">
//                   <i className='bx bx-pie-chart-alt icon'></i>
//                   <span className="text nav-text">Revenue</span>
//                 </a>
//               </li>

//               <li className="nav-link">
//                 <a href="#">
//                   <i className='bx bx-user icon'></i>
//                   <span className="text nav-text">Doctor Visit</span>
//                 </a>
//               </li>

//               <li className="nav-link">
//                 <a href="#">
//                 <i className='bx bx-log-out icon'></i>
//                 <span className="text nav-text">Logout</span>
//                 </a>
//               </li>
//             </ul>
//           </div>

      
//         </div>
//       </nav>

     
//     </div>
//     <div className="main-content">
//         <header>
        

//           <section className="doctorsearchbox">
//             <div className="search--notification--profile">
//               <div className="search">
//                 <input type="text" placeholder="Search" />
//                 <button>
//                   <i className="ri-search-2-line"></i>
//                 </button>
//               </div>
          
//               <div className="notification--profile">
           
//                 <div className="picon bell">
//                   <i className="ri-notification-2-line"></i>
//                 </div>
//                 <div className="picon chat">
//                   <i className="ri-wechat-2-line"></i>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </header>
//       </div>
   
//     </>
//   )
// }

// export default Patientdashboardheader;
