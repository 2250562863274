import React from 'react'
import Drdashheader from '../component/drdashheader'
import Doctorsetting from '../component/doctorsetting'

const Doctorsettingpage = () => {
  return (
    <>
    {<Drdashheader/>}
    {<Doctorsetting/>}
      
    </>
  )
}

export default Doctorsettingpage;
