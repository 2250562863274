import { useState,useEffect } from "react";
import "../css/doctorsetting.css"
import axios from "axios";



const Doctorsetting = () => {

  const [doctor_name, doctor_nameSet] = useState('');
  const [doctor_email, doctor_emailSet] = useState(''); 
  const [position, positionSet] = useState('');
  const [speciality, specialitySet] = useState('');
  const [experience, experienceSet] = useState('');
  const [gender, genderSet] = useState('');




  const nameHandler =(event)=>{
    doctor_nameSet(event.target.value)
  }
  const emailHandler =(event)=>{
    doctor_emailSet(event.target.value)
  }
  const positionHandler =(event)=>{
    positionSet(event.target.value)
  }
  const specialityHandler =(event)=>{
    specialitySet(event.target.value)
  }
  const experienceHandler =(event)=>{
    experienceSet(event.target.value)
  }
  const genderHandler =(event)=>{
    genderSet(event.target.value)
  }


  useEffect(() => {
    const id = localStorage.getItem('id');
    singledoctorlistData(id);
  }, []);

  const singledoctorlistData = (doctor_id) => {
    return axios.get('http://localhost:8080/doctor/singledoctorlist/'+doctor_id)
      .then((response) => {
        // console.log(response.data.message[0]);
        doctor_nameSet(response.data.message[0].doctor_name);
        doctor_emailSet(response.data.message[0].doctor_email);
        positionSet(response.data.message[0].position);
        specialitySet(response.data.message[0].speciality);
        experienceSet(response.data.message[0].experience);
        genderSet(response.data.message[0].gender);
      })
    
  };


  
  
    const submitHandler = (event) =>{
      // event.preventDefault();
      const id = localStorage.getItem('id');
      const drregistrationData = {doctor_name,doctor_email,position,speciality,experience,gender};

      return axios.put('http://localhost:8080/doctor/updatedoctor/'+id,drregistrationData).then((response)=>{
        console.log(response.data.message);
      })
    }


  return (
    <>


<form className="settingcontainer" name="setting" onSubmit={submitHandler}>
      <h1 className="account-setting">Account Settings</h1>
      
      <div className="setting-form-group">
        <label className="account-info" htmlFor="name">Doctor Name</label>
        <div className="input-container">
          <input className="doctor-setting-input" type="text" name='name' id="name" value={doctor_name} onChange={nameHandler} />   
        </div>
      </div>

      <div className="setting-form-group">
        <label className="account-info" htmlFor="email">Doctor Email</label>
        <div className="input-container">
        <input className="doctor-setting-input" type="email" name='email' id="email" value={doctor_email} onChange={emailHandler} />   
        </div>
      </div>

      <div className="setting-form-group">
        <label className="account-info" htmlFor="position">Position</label>
        <div className="input-container">
        <input className="doctor-setting-input" type="text" name='position' id="position" value={position} onChange={positionHandler} />   
        </div>
      </div>

      <div className="setting-form-group">
        <label className="account-info" htmlFor="speciality">Speciality</label>
        <div className="input-container">
        <input className="doctor-setting-input" type="text" name='speciality' id="speciality" value={speciality} onChange={specialityHandler} />  
        </div>
      </div>

      <div className="setting-form-group">
        <label className="account-info" htmlFor="experience">Experience</label>
        <div className="input-container">
        <input className="doctor-setting-input" type="text" name='experience' id="experience" value={experience} onChange={experienceHandler} />  

        </div>
      </div>

      <div className="setting-form-group">
        <label className="account-info" htmlFor="gender">Gender</label>
        <div className="input-container">
        <input className="doctor-setting-input" type="text" name='gender' id="gender" value={gender} onChange={genderHandler} />  

        </div>
      </div>

      <button className="doctor-setting-button">
        Update
        <svg className="doctor-setting-button-icon" viewBox="0 0 24 24" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </form>

    </>
  )
}

export default Doctorsetting;
