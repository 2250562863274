import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FaCamera } from "react-icons/fa";

const Doctorprofile = () => {
  const [userlist, setuserlist] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [showModal, setShowModalphoto] = useState(false);

  useEffect(() => {
    const id = localStorage.getItem("id");
    userlistData(id);
  }, []);

  const userlistData = (doctor_id) => {
    axios
      .get(`http://localhost:8080/doctor/singledoctorlist/${doctor_id}`)
      .then((response) => {
        setuserlist(response.data.message);
      });
  };

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);
    handleImageUpload(event.target.files[0]);
  };

  const handleImageUpload = (file) => {
    const formData = new FormData();
    const id = localStorage.getItem("id");

    formData.append("image", file);
    formData.append("id", id);

    axios
      .post("http://localhost:8080/doctor/uploaddoctorimage", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log(response.data.message);
        userlistData(id);
        setShowModalphoto(false);
      })
      .catch((error) => {
        console.error("Image upload failed:", error);
      });
  };

  const handleRemovePhoto = () => {
    const id = localStorage.getItem("id");
    axios
      .post(`http://localhost:8080/doctor/removedoctorimage`, { id })
      .then((response) => {
        userlistData(id);
        setShowModalphoto(false);
      })
      .catch((error) => {
        console.error("Image removal failed:", error);
      });
  };

  const Modal = ({ onClose }) => (
    <div className="modal-overlay1">
      <div className="modal-content1">
        <div className="modal-title">Change Profile Photo</div>
        <div className="modal-options">
          <label className="modal-option">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            Upload Photo
          </label>
          <button className="modal-option remove" onClick={handleRemovePhoto}>
            Remove Current Photo
          </button>
          <button className="modal-option cancel" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="profile-picture-container">
      <div className="profile-picture">
        {userlist && userlist.map((user) => (
          <div key={user.id} className="image-container">
            {user.doctor_image ? (
              <img
                className="doctor-profile-image"
                src={'http://localhost:8080/'+user.doctor_image}
                alt=""
              />
            ) : (
              <div className="default-picture">No image</div>
            )}
          </div>
        ))}
      </div>
      <button className="camera-button" onClick={() => setShowModalphoto(true)}>
        <FaCamera />
      </button>
      {showModal && <Modal onClose={() => setShowModalphoto(false)} />}
      {userlist && userlist.map((user) => (
              <tr key={user.id}>
               
                <td className="doctor_name" data-label="Name">{user.doctor_name}</td>

              </tr>
            ))} 
    </div>
  );
};

export default Doctorprofile;







// yeh original file hai

// import React, { useState, useEffect } from "react";
// import axios from 'axios';
// import { FaCamera } from "react-icons/fa";
// const Doctorprofile = () => {
 
//     const [userlist, setuserlist] = useState([]);
  

    
//   useEffect(() => {
//     const id = localStorage.getItem('id');
//     userlistData(id);
//   }, []);

//   const userlistData = (doctor_name) => {
//     return axios.get('http://localhost:8080/doctor/singledoctorlist/'+doctor_name)
//       .then((response) => {
//         setuserlist(response.data.message);
//       })
//   };

 

//   return (
//     <>
//     <div className="profile-picture-container">
//           <div className="profile-picture">
            
//           {userlist && userlist.map((user) => (
//             <>
//             <img className='doctor-profile-image' src={'image/'+user.doctor_image}/>
//             <div className="default-picture">No image</div>
//             </>
//         ))}
//           </div>
//           <input
//             type="file"
//             accept="image/*"
//             style={{ display: "none" }}
//             id="fileInput"
//           />
//           <label htmlFor="fileInput" className="upload-button">
//             <FaCamera />
//           </label>

//           {userlist && userlist.map((user) => (
//               <tr key={user.id}>
               
//                 <td className="doctor_name" data-label="Name">{user.doctor_name}</td>

//               </tr>
//             ))} 
      
//         </div>
      
//     </>
//   )
// }

// export default Doctorprofile;



// yeh vo file hai jisme image show ho pa rahi hai 

// import React, { useState, useEffect } from "react";
// import axios from 'axios';
// import { FaCamera } from "react-icons/fa";

// const Doctorprofile = () => {
//   const [userlist, setuserlist] = useState([]);
//   const [imageFile, setImageFile] = useState(null);
//   const [showModal, setShowModalphoto] = useState(false);

//   useEffect(() => {
//     const id = localStorage.getItem("id");
//     userlistData(id);
//   }, []);

//   const userlistData = (doctor_id) => {
//     axios
//       .get(`http://localhost:8080/doctor/singledoctorlist/${doctor_id}`)
//       .then((response) => {
//         setuserlist(response.data.message);
//       });
//   };

//   const handleFileChange = (event) => {
//     setImageFile(event.target.files[0]);
//     handleImageUpload(event.target.files[0]);
//   };

//   const handleImageUpload = (file) => {
//     const formData = new FormData();
//     const id = localStorage.getItem("id");

//     formData.append("image", file);
//     formData.append("id", id);

//     axios
//       .post("http://localhost:8080/doctor/uploaddoctorimage", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       })
//       .then((response) => {
//         console.log(response.data.message);
//         userlistData(id);
//         setShowModalphoto(false);
//       })
//       .catch((error) => {
//         console.error("Image upload failed:", error);
//       });
//   };

//   const handleRemovePhoto = () => {
//     const id = localStorage.getItem("id");
//     axios
//       .post(`http://localhost:8080/doctor/removedoctorimage`, { id })
//       .then((response) => {
//         userlistData(id);
//         setShowModalphoto(false);
//       })
//       .catch((error) => {
//         console.error("Image removal failed:", error);
//       });
//   };

//   const Modal = ({ onClose }) => (
//     <div className="modal-overlay1">
//       <div className="modal-content1">
//         <div className="modal-title">Change Profile Photo</div>
//         <div className="modal-options">
//           <label className="modal-option">
//             <input
//               type="file"
//               accept="image/*"
//               onChange={handleFileChange}
//               style={{ display: "none" }}
//             />
//             Upload Photo
//           </label>
//           <button className="modal-option remove" onClick={handleRemovePhoto}>
//             Remove Current Photo
//           </button>
//           <button className="modal-option cancel" onClick={onClose}>
//             Cancel
//           </button>
//         </div>
//       </div>
//     </div>
//   );

//   return (
//     <div className="profile-picture-container">
//       <div className="profile-picture">
//         {userlist && userlist.map((user) => (
//           <>
//           <img
//             key={user.id}
//             className="doctor-profile-image"
//             src={`http://localhost:8080/${user.doctor_image}`}
        
//           />
//           <div className="default-picture">No image</div>
//           </>
//         ))}
//       </div>
//       <button className="camera-button" onClick={() => setShowModalphoto(true)}>
//         <FaCamera />
//       </button>
//       {showModal && <Modal onClose={() => setShowModalphoto(false)} />}
//     </div>
//   );
// };

// export default Doctorprofile;







// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaCamera } from "react-icons/fa";

// const Doctorprofile = () => {
//     const [userlist, setuserlist] = useState([]);
//     const [selectedFile, setSelectedFile] = useState(null);

//     useEffect(() => {
//         const id = localStorage.getItem("id");
//         userlistData(id);
//     }, []);

//     const userlistData = (doctor_id) => {
//         axios.get(`http://localhost:8080/doctor/singledoctorlist/${doctor_id}`)
//             .then((response) => {
//                 setuserlist(response.data.message);
//             })
//             .catch((err) => console.error(err));
//     };

//     const handleFileChange = (e) => {
//         setSelectedFile(e.target.files[0]);
//     };

//     const handleFileUpload = () => {
//         const id = localStorage.getItem("id"); // Get the doctor's ID
//         const formData = new FormData();
//         formData.append("id", id);
//         formData.append("image", selectedFile);

//         axios.post("http://localhost:8080/doctor/uploaddoctorimage", formData, {
//             headers: { "Content-Type": "multipart/form-data" },
//         })
//             .then((response) => {
//                 console.log(response.data.message);
//                 userlistData(id); // Refresh the profile data
//             })
//             .catch((err) => console.error(err));
//     };

//     return (
//         <>
//             <div className="profile-picture-container">
//                 <div className="profile-picture">
//                     {userlist.map((user) => (
//                         user.doctor_image ? (
//                             // <img
//                             //     className="doctor-profile-image"
//                             //     src={`http://localhost:8080/${user.doctor_image}`}
//                             //     alt="Doctor"
//                             // />
//                             <img className='doctor-profile-image' src={'/hospitalapi/uploads'+user.doctor_image}/>
                        
//                         ) : (
//                             <div className="default-picture">No image</div>
//                         )
//                     ))}
//                 </div>
//                 <input
//                     type="file"
//                     accept="image/*"
//                     onChange={handleFileChange} 
//                     style={{ display: "none" }}
//                     id="fileInput"
//                 />
//                 <label htmlFor="fileInput" className="upload-button">
//                     <FaCamera />
//                 </label>
//                 <button onClick={handleFileUpload} >Upload</button>
//             </div>
//             <div>
//                 {userlist.map((user) => (
//                     <div key={user.id}>
//                         <h4>{user.doctor_name}</h4>
//                     </div>
//                 ))}
//             </div>
//         </>
//     );
// };

// export default Doctorprofile;







  












// DoctorProfile.js
// import React, { useState, useEffect } from "react";
// import axios from 'axios';
// import { FaCamera } from "react-icons/fa";


// const Doctorprofile = () => {
//   const [userlist, setuserlist] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const id = localStorage.getItem('id');
//     if (id) {
//       userlistData(id);
//     }
//   }, []);

//   const userlistData = (doctor_id) => {
//     return axios.get(`http://localhost:8080/doctor/singledoctorlist/${doctor_id}`)
//       .then((response) => {
//         setuserlist(response.data.message);
//       })
//       .catch((error) => {
//         console.error("Error fetching doctor data:", error);
//         setError("Failed to load doctor profile");
//       });
//   };

//   const handleImageUpload = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;

//     const formData = new FormData();
//     formData.append('doctor_image', file);
//     formData.append('doctor_id', localStorage.getItem('id'));

//     setLoading(true);
//     setError(null);

//     try {
//       const response = await axios.post(
//         'http://localhost:8080/doctor/uploaddoctorimage',
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );

//       if (response.data.status === 200) {
//         // Refresh the user data to show new image
//         userlistData(localStorage.getItem('id'));
//       }
//     } catch (err) {
//       setError("Failed to upload image. Please try again.");
//       console.error("Error uploading image:", err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="doctor-profile-wrapper">
//       <div className="profile-picture-container">
//         <div className="profile-picture">
//           {userlist[0]?.doctor_image ? (
//             <img
//               className="doctor-profile-image"
//               src={`/image/${userlist[0].doctor_image}`}
              
//             />
//           ) : (
//             <div className="default-picture">
//               No image
//             </div>
//           )}
          
//           <input
//             type="file"
//             accept="image/*"
//             onChange={handleImageUpload}
//             className="file-input"
//             id="fileInput"
//             disabled={loading}
//           />
          
//           <label
//             htmlFor="fileInput"
//             className="upload-button"
//           >
//             <FaCamera />
//           </label>
//         </div>

//         {loading && (
//           <div className="status-message loading">Uploading...</div>
//         )}
        
//         {error && (
//           <div className="status-message error">{error}</div>
//         )}

//         {userlist[0] && (
//           <div className="doctor-info">
//             <h2 className="doctor-name">{userlist[0].doctor_name}</h2>
          
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Doctorprofile;


